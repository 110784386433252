// src/components/AboutUs.js
import React from 'react';
import { Container, Row, Col } from 'react-bootstrap';

const AboutUs = () => (
  <section id="about" className="about-us">
    <Container>
      <h2 className="text-center">About Us</h2>
      <Row className="about-content justify-content-center">
        <Col md={8} className="about-text">
          <p>
            At KH Solve, we're passionate about enhancing the way businesses work. Founded by experts in             data science and machine learning — holding PhDs in mathematics and statistics — our mission is              to help companies save time, reduce errors, and unlock the full potential of their data.
          </p>
          <p>
            Our team brings together a unique blend of analytical skills and practical know-how and we                   believe that even small changes can make a big difference. Whether it's automating routine                   processes, uncovering insights from your data, or implementing AI solutions, we're here to make              your work life easier and your business run more smoothly.
          </p>
          <p>
            We empower businesses with the tools and insights they need to thrive in a data-driven world. By             working closely with our clients to understand their unique challenges, we develop tailored                  strategies for success.
          </p>
          <p>
            We are a small business with big ideas, and are committed to making a significant impact. Let us             partner with you to save time, enhance efficiency, and allow you to focus on what really matters.
          </p>
        </Col>
      </Row>
    </Container>
  </section>
);

export default AboutUs;

