// src/components/Footer.js
import React from 'react';
import { Container } from 'react-bootstrap';

const Footer = () => {
  const scrollToTop = () => {
    window.scrollTo({ top: 0, behavior: 'smooth' });
  };

  return (
    <footer className="footer">
      <Container className="text-center">
        {/* Replace the anchor with a button for 'Back to top' */}
        <p className="mb-2">
          <button onClick={scrollToTop} className="btn btn-link text-decoration-none back-to-top">
            Back to top
          </button>
        </p>
        <div className="footer-icons mb-2">
          <ul className="social-icons list-inline">
            <li className="list-inline-item"><strong>Follow:</strong></li>
            <li className="list-inline-item">
              <a href="https://linkedin.com" rel="nofollow noopener noreferrer" className="text-decoration-none">
                <i className="bi bi-linkedin"></i> LinkedIn
              </a>
            </li>
            <li className="list-inline-item">
              <a href="https://twitter.com" rel="nofollow noopener noreferrer" className="text-decoration-none">
                <i className="bi bi-twitter"></i> Twitter
              </a>
            </li>
          </ul>
        </div>
        <p className="copyright text-center mb-0">
          &copy; {new Date().getFullYear()} - KH Solve · 
          <a href="/privacy" className="text-decoration-none"> Privacy</a> · 
          <a href="/terms" className="text-decoration-none"> Terms</a>
        </p>
      </Container>
    </footer>
  );
};

export default Footer;

