// src/components/AttractorCanvas.js
import React, { useEffect, useRef } from 'react';

const AttractorCanvas = () => {
  const canvasRef = useRef(null);
  const animationFrameIdRef = useRef(null);

  useEffect(() => {
    const canvas = canvasRef.current;
    const ctx = canvas.getContext('2d');
    let rotationX = 0,
      rotationY = 0;

    const a = 2;
    let x = -4.5,
      y = 0.2,
      z = 0.1;
    const dt = 0.01;
    const scaleFactor = 100;
    const tsteps = 4900; // Reduced number of steps
    const hiroshigeColors = [
      '#aadce0',
      '#ef8a47',
      '#376795',
      '#ffd06f',
      '#72bcd5',
      '#ffe6b7',
      '#e76254',
      '#1e466e',
      '#528fad',
      '#f7aa58',
    ];

    let points = [];
    let colors = [];

    function resizeCanvas() {
      canvas.width = window.innerWidth;
      canvas.height = window.innerHeight;
    }

    resizeCanvas();
    window.addEventListener('resize', resizeCanvas);

    function interpolateColor(color1, color2, factor) {
      const r1 = parseInt(color1.substring(1, 3), 16);
      const g1 = parseInt(color1.substring(3, 5), 16);
      const b1 = parseInt(color1.substring(5, 7), 16);

      const r2 = parseInt(color2.substring(1, 3), 16);
      const g2 = parseInt(color2.substring(3, 5), 16);
      const b2 = parseInt(color2.substring(5, 7), 16);

      const r = Math.round(r1 + (r2 - r1) * factor);
      const g = Math.round(g1 + (g2 - g1) * factor);
      const b = Math.round(b1 + (b2 - b1) * factor);

      return `rgb(${r},${g},${b})`;
    }

    function getColor(i, total) {
      const numColors = hiroshigeColors.length;
      const segmentLength = total / (numColors - 1);
      const colorIndex = Math.floor(i / segmentLength);
      const nextColorIndex = (colorIndex + 1) % numColors;
      const color1 = hiroshigeColors[colorIndex];
      const color2 = hiroshigeColors[nextColorIndex];
      const factor = (i % segmentLength) / segmentLength;
      return interpolateColor(color1, color2, factor);
    }

    function computeAttractor() {
      for (let i = 0; i < tsteps; i++) {
        let dx = -a * x - 4 * y - 4 * z - y * y;
        let dy = -a * y - 4 * z - 4 * x - z * z;
        let dz = -a * z - 4 * x - 4 * y - x * x;

        x += dx * dt;
        y += dy * dt;
        z += dz * dt;

        points.push({
          x: x * scaleFactor,
          y: y * scaleFactor,
          z: z * scaleFactor,
        });
        colors.push(getColor(i, tsteps));
      }
    }

    function drawAttractor() {
      ctx.clearRect(0, 0, canvas.width, canvas.height);
      ctx.save();
      ctx.translate(canvas.width / 2, canvas.height / 2);

      const cosRY = Math.cos(rotationY);
      const sinRY = Math.sin(rotationY);
      const cosRX = Math.cos(rotationX);
      const sinRX = Math.sin(rotationX);

      for (let i = 1; i < points.length; i++) {
        const { x: x1, y: y1, z: z1 } = points[i - 1];
        const { x: x2, y: y2, z: z2 } = points[i];

        // Rotate point i-1
        const x1RY = x1 * cosRY - z1 * sinRY;
        const z1RY = z1 * cosRY + x1 * sinRY;
        const y1RX = y1 * cosRX - z1RY * sinRX;

        // Rotate point i
        const x2RY = x2 * cosRY - z2 * sinRY;
        const z2RY = z2 * cosRY + x2 * sinRY;
        const y2RX = y2 * cosRX - z2RY * sinRX;

        ctx.beginPath();
        ctx.moveTo(x1RY, y1RX);
        ctx.lineTo(x2RY, y2RX);
        ctx.strokeStyle = colors[i];
        ctx.lineWidth = 3.0;
        ctx.stroke();
      }

      ctx.restore();
    }

    let lastTime = 0;
    const fpsInterval = 1000 / 30; // 30 FPS

    function animate(time) {
      if (!lastTime) lastTime = time;
      const delta = time - lastTime;

      if (delta > fpsInterval) {
        lastTime = time - (delta % fpsInterval);
        drawAttractor();
      }

      animationFrameIdRef.current = requestAnimationFrame(animate);
    }

    function onMouseMove(event) {
      const rect = canvas.getBoundingClientRect();
      const mouseX = event.clientX - rect.left;
      const mouseY = event.clientY - rect.top;

      rotationY = (mouseX / canvas.width - 0.5) * 2 * Math.PI * 0.01;
      rotationX = (mouseY / canvas.height - 0.5) * 2 * Math.PI * 0.01;
    }

    computeAttractor();

    // Attach mousemove listener to the hero banner
    const heroBanner = document.getElementById('hero');
    if (heroBanner) {
      heroBanner.addEventListener('mousemove', onMouseMove);
    }

    animate();

    return () => {
      // Cleanup event listeners and animation frame
      window.removeEventListener('resize', resizeCanvas);
      if (heroBanner) {
        heroBanner.removeEventListener('mousemove', onMouseMove);
      }
      cancelAnimationFrame(animationFrameIdRef.current);
    };
  }, []);

  return <canvas ref={canvasRef} id="attractorCanvas" />;
};

export default AttractorCanvas;

