// src/components/Services.js
import React from 'react';
import { Container, Row, Col } from 'react-bootstrap';

const services = [
  {
    img: 'assets/ds_icon.svg',
    title: 'Data Science & Bayesian Analysis',
    description:
      'Our team uses advanced Bayesian methods to transform complex data into useful insights, giving your business the clarity needed to make informed decisions.',
  },
  {
    img: 'assets/ml_icon.svg',
    title: 'Machine Learning & AI',
    description:
      'From predictive modelling to AI-driven automation, we use the latest machine learning techniques to give your business the advantage it deserves.',
  },
  {
    img: 'assets/math_icon.svg',
    title: 'Mathematical & Statistical Modelling',
    description:
      'Our mathematical models offer strong solutions for optimising your operations, forecasting trends, and reducing risks, helping you make business decisions backed by real data and insights.',
  },
  {
    img: 'assets/autom_icon.svg',
    title: 'Business Process Automation',
    description:
      'We streamline your operations by automating tasks, freeing up your team to focus on what really drives your business forward.',
  },
];

const Services = () => (
  <section id="services" className="services" aria-labelledby="services-heading">
    <Container>
      <h2 id="services-heading" className="section-title">Services</h2>
      <Row>
        {services.map((service, index) => (
          <Col md={6} key={index} className="service">
            <img src={service.img} alt={`${service.title} Icon`} />
            <h3>{service.title}</h3>
            <p>{service.description}</p>
          </Col>
        ))}
      </Row>
    </Container>
  </section>
);

export default Services;

