// src/components/HeroBanner.js
import React from 'react';
import { Button } from 'react-bootstrap';
import AttractorCanvas from './AttractorCanvas';

const HeroBanner = () => (
  <header id="hero" className="hero-banner">
    <AttractorCanvas />
    <div className="content">
      <img src="assets/KHlogo.svg" alt="KH Solve Logo" />
      <p className="mission-statement">Empowering Your Business with Data, Automation, and AI Solutions</p>
      <Button
        href="#contact"
        className="custom-button"
        size="lg"
        aria-label="Contact us"
      >
        Contact us
      </Button>
    </div>
  </header>
);

export default HeroBanner;

