// src/components/Navbar.js

import React, { useState } from 'react';
import { Navbar, Nav, Container } from 'react-bootstrap';

const NavigationBar = () => {
  const [expanded, setExpanded] = useState(false);

  const handleNavClick = () => {
    setExpanded(false);
  };

  return (
    <Navbar 
      expand="lg" 
      fixed="top" 
      className="navbar navbar-dark"
      expanded={expanded}
      onToggle={setExpanded}
    >
      <Container fluid className="navbar-container">
        <Navbar.Brand href="#hero" className="navbar-brand">
          KH Solve
        </Navbar.Brand>
        <Navbar.Toggle aria-controls="navbarNav" className="navbar-toggler" />
        <Navbar.Collapse id="navbarNav" className="collapse navbar-collapse">
          <Nav className="ms-auto navbar-nav">
            <Nav.Link href="#hero" className="nav-link" onClick={handleNavClick}>
              Home
            </Nav.Link>
            <Nav.Link href="#services" className="nav-link" onClick={handleNavClick}>
              Services
            </Nav.Link>
            <Nav.Link href="#about" className="nav-link" onClick={handleNavClick}>
              About Us
            </Nav.Link>
            <Nav.Link href="#contact" className="nav-link" onClick={handleNavClick}>
              Contact
            </Nav.Link>
          </Nav>
        </Navbar.Collapse>
      </Container>
    </Navbar>
  );
};

export default NavigationBar;
